<template>
  <div class="navbar">
    <div class="container">
      <div class="navbar-wrapper">
        <a href="#" class="">
          <img src="/images/logo-2.svg" loading="lazy" alt="Amorino Logo" class="logo-image"/>
        </a>
        <nav role="navigation" class="nav-menu">
          <a href="https://www.amorinogelato.com/" class="nav-link">Amorino DELIVERY</a>
          <router-link to="/login" class="nav-link">¿Ya eres miembro? Inicia Sesión</router-link>
          <div class="button" @click="$router.push('/signup')">HAZTE MIEMBRO</div>
        </nav>
        <nav role="navigation" class="mobile-nav-menu" v-if="displayMenu">
          <a href="#" class="nav-link">Amorino Deliver</a>
          <router-link to="/login" class="nav-link">¿Ya eres miembro? Inicia Sesión</router-link>
          <div class="button" @click="$router.push('/signup')">HAZTE MIEMBRO</div>
        </nav>
        <div class="mobile-menu-button" @click="displayMenu = !displayMenu">
          <svg viewBox="0 0 100 80" width="30" height="30">
            <rect width="100" height="12"></rect>
            <rect y="30" width="100" height="12"></rect>
            <rect y="60" width="100" height="12"></rect>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Navbar',
  data() {
    return {
      displayMenu: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  z-index: 1000;
}
.nav-link {
  color: black;
  text-decoration: none;
  margin: 0 20px;
}
.mobile-nav-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding-bottom: 25px;
  background-color: #fff;
  text-transform: uppercase;
}
.mobile-menu-button {
  display: none;
  cursor: pointer;
}
.button {
  cursor: pointer;
}
@media screen and (max-width: 991px) {
  .nav-link {
    display: block;
    height: 40px;
    text-align: center;
  }
  .nav-menu {
    display: none;
  }
  .mobile-nav-menu {
    display: flex;
  }
  .mobile-menu-button {
    display: block;
  }
}
</style>
