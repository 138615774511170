<template>
  <div class="home">
  <Navbar />
  <div class="section hero-section">
    <div class="container">
      <div class="hero-wrapper">
        <div class="hero-content">
          <h1 class="hero-title">AMORINO CLUB<br>ES <span class="typed-words" /></h1>
          <h2 class="heading">Una comunidad de familia y amigos que disfrutan la vida del lujo italiano al máximo.</h2>
          <div class="signup-form">
            <form name="email-form" class="form" @submit.prevent="goToFormSubscription">
              <input type="text" class="input base-input" maxlength="256" name="email" placeholder="Dirección de correo electrónico" v-model="email">
              <input type="submit" value="INICIAR" class="submit-button base-button"></form>
          </div>
        </div><img src="/images/animation.png" loading="lazy" alt="Helado Amorino" class="hero-image">
      </div>
    </div>
  </div>
  <div class="marquee">
    <div class="track track-front">
      <div class="marquee-text">EXCLUSIVIDAD</div>
      <div class="marquee-text">·</div>
      <div class="marquee-text">INVITACIONES</div>
      <div class="marquee-text">·</div>
      <div class="marquee-text">CELEBRACIONES</div>
      <div class="marquee-text">·</div>
      <div class="marquee-text">PREFERENCIA</div>
      <div class="marquee-text">·</div>
      <div class="marquee-text">EXCLUSIVIDAD</div>
      <div class="marquee-text">·</div>
      <div class="marquee-text">INVITACIONES</div>
      <div class="marquee-text">·</div>
      <div class="marquee-text">CELEBRACIONES</div>
      <div class="marquee-text">·</div>
      <div class="marquee-text">PREFERENCIA</div>
      <div class="marquee-text">·</div>
    </div>
  </div>
  <div class="section first-benefit-section">
    <div class="container">
      <div class="benefit-wrapper">
        <div class="benefit-text">
          <h2 class="benefit-header">EXCLUSIVIDAD</h2>
          <p class="benefit-paragraph">Recibe Gelato mensualmente, notas secretas del origen de los sabores y vive la experiencia detrás de vitrina no disponible para el público general.</p>
          <div class="spacer"></div>
          <h2 class="benefit-header">INVITACIONES</h2>
          <p class="benefit-paragraph">Disfruta de eventos exclusivos con expertos en coctelería y degustaciones privadas de sabores de temporada.</p>
        </div>
        <div class="benefit-image" data-aos="zoom-in" data-aos-offset="200"><img src="/images/img83.jpeg" loading="lazy" alt="Helado Amorino visto desde arriba" class="image"></div>
      </div>
    </div>
  </div>
  <div class="section second-benefit-section">
    <div class="container">
      <div class="benefit-wrapper">
        <div class="benefit-image" data-aos="zoom-in" data-aos-offset="200"><img src="/images/img109.jpeg" loading="lazy" alt="Dos mujeres compartiendo un helado" class="image"></div>
        <div class="benefit-text">
          <h2 class="benefit-header">CELEBRACIONES</h2>
          <p class="benefit-paragraph">Recibe regalos especiales para celebrar tu cumpleaños así como para compartir cada aniversario de Amorino México.</p>
          <div class="spacer"></div>
          <h2 class="benefit-header">PREFERENCIA</h2>
          <p class="benefit-paragraph">Precio especial para miembros en boutique, así como una fila exclusiva para que tú y tu familia vivan la experiencia Amorino en el menor tiempo posible.</p>
        </div>
      </div>
    </div>
  </div>
  <div class="marquee">
    <div class="track track-back">
      <div class="marquee-text">EXCLUSIVIDAD</div>
      <div class="marquee-text">·</div>
      <div class="marquee-text">INVITACIONES</div>
      <div class="marquee-text">·</div>
      <div class="marquee-text">CELEBRACIONES</div>
      <div class="marquee-text">·</div>
      <div class="marquee-text">PREFERENCIA</div>
      <div class="marquee-text">·</div>
      <div class="marquee-text">EXCLUSIVIDAD</div>
      <div class="marquee-text">·</div>
      <div class="marquee-text">INVITACIONES</div>
      <div class="marquee-text">·</div>
      <div class="marquee-text">CELEBRACIONES</div>
      <div class="marquee-text">·</div>
      <div class="marquee-text">PREFERENCIA</div>
      <div class="marquee-text">·</div>
    </div>
  </div>
  <div class="section">
    <div class="container">
      <div class="flowers">
        <div class="flower-image"><img src="/images/Amorino03.png" loading="lazy" alt="" class="image"></div>
        <div class="flower-image"><img src="/images/Amorino01.png" loading="lazy" alt="" class="image"></div>
        <div class="flower-image"><img src="/images/Amorino02.png" loading="lazy" alt="" class="image"></div>
        <div class="flower-image"><img src="/images/Amorino04.png" loading="lazy" alt="" class="image"></div>
        <div class="flower-image"><img src="/images/Amorino05.png" loading="lazy" alt="" class="image"></div>
      </div>
      <div class="additional-benefits">
        <h1 class="benefits-header">¿QUÉ ESPERAS PARA VOLVERTE MIEMBRO?</h1>
        <div class="benefits-wrapper">
          <div class="benefits-left">
            <div class="additional-benefit">
              <h2 class="additional-benefit-header">FILA Y PRECIO EXCLUSIVO PARA MIEMBROS</h2>
              <p>Como miembro, habrá señalizaciones en el piso para que no hagas fila y tendrás derecho a un precio preferencial en boutique equivalente al 10% de descuento en todos los productos.</p>
            </div>
            <div class="additional-benefit">
              <h2 class="additional-benefit-header">EXPERIENCIA AMORINO</h2>
              <p>Como miembro, puedes vivir la experiencia de hacer tu propia rosa en tienda además de conocer todos los sabores.</p>
            </div>
          </div>
          <div class="benefits-right">
            <div class="additional-benefit">
              <h2 class="additional-benefit-header">OFERTAS DE CUMPLEAÑOS</h2>
              <p>Recibe un Waffle La Bella durante el mes de tu cumpleaños para una celebración perfecta y otro más durante el mes de aniversario de Amorino México.</p>
            </div>
            <div class="additional-benefit">
              <h2 class="additional-benefit-header">EVENTOS SOLO PARA MIEMBROS</h2>
              <p>Accede a invitaciones de noches de coctelería, degustaciones de sabores de temporada y otros eventos exclusivos.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="flowers">
        <div class="flower-image"><img src="/images/Amorino04.png" loading="lazy" alt="" class="image"></div>
        <div class="flower-image"><img src="/images/Amorino05.png" loading="lazy" alt="" class="image"></div>
        <div class="flower-image"><img src="/images/Amorino03.png" loading="lazy" alt="" class="image"></div>
        <div class="flower-image"><img src="/images/Amorino02.png" loading="lazy" alt="" class="image"></div>
        <div class="flower-image"><img src="/images/Amorino01.png" loading="lazy" alt="" class="image"></div>
      </div>
    </div>
  </div>
  <div class="footer-section">
    <div class="container">
      <div class="footer">
        <div>2021 © Amorino México · </div>
        <a href="#" class="link">Términos y condiciones</a>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
/* global Typed */
// @ is an alias to /src
import AOS from 'aos';
import 'aos/dist/aos.css';
import Navbar from '@/components/Navbar.vue';

export default {
  name: 'Home',
  data() {
    return {
      email: '',
    };
  },
  components: {
    Navbar,
  },
  methods: {
    callback() {
      // eslint-disable-next-line no-new
      new Typed('.typed-words', {
        strings: ['CELEBRACIONES', 'PREFERENCIA', 'EXCLUSIVIDAD', 'INVITACIONES'],
        typeSpeed: 75,
        backSpeed: 50,
        backDelay: 800,
        startDelay: 500,
        loop: true,
        showCursor: false,
        attr: null,
      });
    },
    goToFormSubscription() {
      this.$router.push({
        path: 'signup',
        query: { email: this.email },
      });
    },
  },
  mounted() {
    AOS.init();
    const script = document.createElement('script');
    script.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/typed.js/2.0.10/typed.min.js');
    script.onload = this.callback;
    document.head.appendChild(script);
  },
};
</script>

<script>

</script>
